/*MOBILE*/
#get-ready {
    text-align: center;
    & p {
        font-size: 11px;
        font-weight: 500;
        color: $dark;
        padding: 3em 0 1em 0;
    }
    & h2 {
        color: $orange-brand;
        font-size: 30px;
        width: 359px;
        margin: 0 auto;
        padding-bottom: .8em;
    }
}

/*SMALL*/
@media #{$query-small} {
    #get-ready {
        
        & h2 {
            width: 100%;
        }
    }
}

/*MEDIUM*/
@media #{$query-medium} {

}

/*LARGE*/
@media #{$query-large} {
    #get-ready {

        & p {
            font-size: 22px;
        }
        & h2 {
            font-size: 52px;
            padding-bottom: 1.5em;
        }
    }
}

/*X-LARGE*/
@media #{$query-xl} {

}

/*XX-LARGE*/
@media #{$query-xxl} {

}
