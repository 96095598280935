.cta {
    display: inline-block;
    text-align: center;
    border-radius: 58px;
    padding: 1em 2em;
    color: $white;
    font-weight: 500;
    font-size: 16px;
    border: solid 1px $orange-brand;
    background-color: #F0380F;
    transition: all .3s;
}
.cta:hover {
    background-color: $white;
    color: $orange-brand;
    cursor: pointer;
}