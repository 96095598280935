@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
    font-family: 'Helvetica Neue', 'Open Sans', sans-serif;
}

/*MOBILE*/
h1 {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.55;
    color: $dark;
    max-width: 1020px;
}
h2 {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.55;
    color: $dark;
}
.big-paragraph {
    text-align: center;
    margin: 0 auto;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.55;
    color: $dark;
}
.small-paragraph {
    padding-top: 2em;
    text-align: center;
    margin: 0 auto;
    font-size: 16px;
    line-height: 1.5;
    padding-bottom: 1em;
    color: $dark;
}

/*SMALL*/
@media #{$query-small}  {
    
}

/*MEDIUM*/
@media #{$query-medium}  {
    
}

/*LARGE*/
@media #{$query-large}  {
    
    h1 {
        font-size: 52px;
        font-weight: 800;
        line-height: 1.4;
    }
    h2 {
        font-size: 48px;
        line-height: 1.4;
        color: $dark;
    }
    .small-paragraph {
        font-size: 22px;
        line-height: 1.55;
        font-weight: 500;
    }
}

/*X-LARGE*/
@media #{$query-xl}  {
    
}

/*XX-LARGE*/
@media #{$query-xxl}  {
    
}
